var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"message-row flex font-regular"},[(_vm.typeList.includes(_vm.msgCon.type))?_c('div',{staticClass:"message-box flex-1 p-l-10 p-r-10"},[_c('div',{staticClass:"message text-primary flex align-center",style:({color: _vm.msgCon.type === '1040' ? '#ffffff' : ' #989cb3'})},[_c('span',{staticClass:"user-label bg-center bg-no-repeat bg-size-cover is-vip",class:{
                    'is-host':_vm.isHost,
                    'is-expert': _vm.isExpert,
                    'is-visitor': _vm.isVisitor
                }}),_c('div',{staticClass:"name-box m-l-5 d-inline-block",class:{
                    'is-jump': !!_vm.msgCon.content && _vm.msgCon.content.jump_url
                },on:{"click":function($event){return _vm.showPopup(_vm.msgCon)}}},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.msgCon.nickname + ':'))]),_vm._v(" "+_vm._s(_vm.msgCon.msg)+" ")])])]):_vm._e(),(['1120'].includes(_vm.msgCon.type))?_c('div',{staticClass:"message-box notice-box flex-1 p-l-10 p-r-10"},[_c('div',{staticClass:"message font-14 text-primary",staticStyle:{"color":"#989cb3"}},[_c('span',{staticClass:"font-14 notice"},[_vm._v(_vm._s('系统提示：'))]),_vm._v(" "+_vm._s(_vm.msgCon.msg))])]):_vm._e(),(['1121'].includes(_vm.msgCon.type))?_c('div',{staticClass:"message-box notice-box flex-1 p-l-10 p-r-10"},[_c('div',{staticClass:"message font-14 text-primary white gift"},[_c('span',{staticClass:"user-label bg-center bg-no-repeat bg-size-cover is-vip m-r-5",class:{
                'is-host':_vm.isHost,
                'is-expert': _vm.isExpert,
            }}),_vm._v(" "+_vm._s(_vm.msgCon.msg)+" "),_c('img',{staticClass:"giftPng",attrs:{"src":_vm.msgCon.img,"alt":""}})])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }