<template>
  <TransitionGroup tag="ul" name="fade" class="new-entry">
    <li v-for="item in entryQueue" class="item" :key="item.nickname">
      <message-row :msgCon="item"></message-row>
    </li>
  </TransitionGroup>
</template>

<script>
import MessageRow from '@/views/Broadcast/components/MessageRow'
export default {
    components: {
        MessageRow
    },
    data () {
        return {
            entryQueue: []
        }
    },
    mounted () {
        // this.interval = setInterval(() => {
        //     if (this.entryQueue.length > 0) {
        //         this.entryQueue.splice(0, 1)
        //     }
        // }, 3000)
    },
    beforeDestroy () {
        clearInterval(this.interval)
    },
    methods: {
        addEntry (item) {
            console.log(item, 'item')
            this.entryQueue.splice(this.entryQueue.length, 0, item)
            // 3 秒后，移除第一个
            setTimeout(() => {
                this.entryQueue.splice(0, 1)
            }, 3000)
        }
    }
}
</script>

<style lang="scss" scoped>
.new-entry {
    width: 100%;
    overflow: hidden;
    padding-left: 10px;
    //position: absolute;
    height: 30px;
    bottom: 50px;
    left: 0;
    padding: 0;
    list-style-type: none;
    color: #fff;
    z-index: 11;
}

.item {
  width: 100%;
  height: 30px;
  box-sizing: border-box;
  color: #fff;
  background: #0e0e23;
}

/* 1. 声明过渡效果 */
.fade-move,
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

/* 2. 声明进入和离开的状态 */
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: scaleY(0.01) translate(30px, 0);
}

/* 3. 确保离开的项目被移除出了布局流
      以便正确地计算移动时的动画效果。 */
.fade-leave-active {
  position: absolute;
}

</style>
