var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-100"},[_c('div',{staticClass:"live-other-item m-b-5"},[_c('div',{staticClass:"live-left"},[_c('div',{staticClass:"live-time font-12 font-medium text-primary font-500"},[_c('span',{staticClass:"scale-9"},[_vm._v(_vm._s(_vm.date))]),_c('span',{staticClass:"m-l-5 scale-9 m-r-5"},[_vm._v("|")]),_c('span',{staticClass:"scale-9"},[_vm._v(_vm._s(_vm.time))]),_c('span',{staticClass:"m-l-15"},[_vm._v(_vm._s(_vm.matchInfo.leagueChsShort))])]),_c('div',{staticClass:"live-info flex align-center"},[_c('div',{staticClass:"team flex flex-end align-center"},[_c('span',{staticClass:"flex-1 m-r-5 font-14 font-regular font-400 text-white text-right text-ellipsis"},[_vm._v(_vm._s(_vm.matchInfo.homeChs))]),_c('span',{staticClass:"team-pic d-inline-block bg-no-repeat bg-center bg-size-100 border-50",style:({
                            backgroundImage: `url(${_vm.matchInfo.homeLogo})`
                       })})]),_c('span',{staticClass:"font-12 font-regular vs text-center font-400 text-white"},[_vm._v("VS")]),_c('div',{staticClass:"team flex align-center"},[_c('span',{staticClass:"team-pic d-inline-block bg-no-repeat bg-center bg-size-100 border-50",style:({
                            backgroundImage: `url(${_vm.matchInfo.awayLogo})`
                       })}),_c('span',{staticClass:"flex-1 m-l-5 font-14 font-regular font-400 text-white text-ellipsis"},[_vm._v(_vm._s(_vm.matchInfo.awayChs))])])])]),_c('div',{staticClass:"live-right text-center",class:{
                'is-subscribed': _vm.isSubscribed
            },on:{"click":_vm.subscribeMatch}},[_c('span',{staticClass:"d-inline-block font-12"},[_vm._v(_vm._s(_vm.subscribeString))])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }